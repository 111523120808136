<template>
  <div class="GalleryFile">
    <IntrinsicSVG size="293" :style="`--image:url(${file.src})`" />
    <ProgressBar :progress="progress" v-if="!complete" />
    <div class="options">
      <a class="button warning" active="button" v-if="hasError" @click="alert(response)">
        <iconic name="circle-exclamation-solid" />
      </a>
      <a class="button success" v-if="complete">
        <iconic name="check-solid" />
      </a>
      <a class="button _loading" dcolor v-if="!complete && !hasError">
        <iconic name="gspinner" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: ["file"],
  data: function() {
    return {
      progress: 0,
      uploadId: Go.uuid(),
      message: "",
      hasError: false,
      response: null,
      complete: false,
      axios: {
        timeout: 1000 * 60 * 30,
        headers: {
          "use-form": "false",
        },
      },
    };
  },
  methods: {
    init: function() {
      this.$socket.io.on(`upload:${this.uploadId}`, (data) => {
        this.progress = data.progress;
        this.progressMessage = data.message;
      });

      this.$socket.io.on(`upload:error:${this.uploadId}`, (data) => {
        this.hasError = true;
        this.response = data;
      });

      this.$socket.io.on(`upload:complete:${this.uploadId}`, (data) => {
        this.complete = true;
        this.message = data.message;
        this.$emit("complete", this.file);
      });
    },
    upload: async function() {
      try {
        const formData = new FormData();
        formData.append("file", this.file.file);
        formData.append("fileName", this.file.name);
        formData.append("uploadId", this.uploadId);
        formData.append("category", this.$route.query.category);

        this.req = await this.$http.post(`${this.$apiHost}gallery/createFile`, formData, this.axios);
        this.response = this.req.data;
        this.res(this.req.data);
      } catch (error) {
        this.hasError = true;
        this.response = error;
        this.error(error);
      }
    },
    res: function({ success, message }) {
      if (!success) {
        this.hasError = true;
        this.message = message;
      }
    },
    error: function(error) {
      this.hasError = true;
      this.message = Go.getErrorMessage(error);
    },
  },
  mounted: function() {
    if (this.complete) {
      return;
    }
    this.init();
    this.upload();
  },
  destroyed: function() {
    this.$socket.io.off(`upload:${this.id}`);
    this.$socket.io.off(`upload:error:${this.id}`);
    this.$socket.io.off(`upload:complete:${this.id}`);
  },
};
</script>

<style lang="scss">
.GalleryFile {
  position: relative;
  .IntrinsicSVG {
    background-image: var(--image);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: $alto;
    max-width: 100%;
    height: auto;
    border-radius: $mpadding/1.5;
  }

  .ProgressBar {
    position: absolute;
    bottom: $mpadding/2;
    left: $mpadding/2;
    right: $mpadding/2;
    max-width: calc(100% - #{$mpadding});
  }

  .options {
    position: absolute;
    top: 0;
    right: 0;
  }

  .button {
    display: inline-flex;
    padding: $mpadding/2;
    font-size: 160%;
    cursor: pointer;
    position: relative;
  }

  .button:not(._loading) .iconic:before {
    content: "";
    position: absolute;
    @include fixedSize($mpadding);
    background-color: var(--theme-background);
    z-index: 0;
  }

  .button:not(._loading) .iconic svg {
    position: relative;
    z-index: 1;
  }

  .button.warning {
    color: red;
  }

  .button.success {
    color: var(--success-color);
  }
}
</style>
